<template>
  <WsMain>
    <WsPartReturnCard
      label="申請部分退訂"
      selectable
      previousUrl="/shop_order"
      :tableFields="_tableFields"
      :fields="fields"
      :modelDataUrl="_modelDataUrl"
      :modelDataParams="_modelDataParams"
      :postTransform="postTransform"
      returnModelName="shop_return_record"
      :parentModelName="parentModelName"
      @input="$_onInput($event)"
    ></WsPartReturnCard>
    <WsPopup ref="remarkPopup">
      <template #title>
        {{remarkPopup.title}}
      </template>
      <template #content>
        {{remarkPopup.content}}
      </template>
      <template #rightActions>
        <WsBtn @click="$_RemarkPopupClose()">關閉</WsBtn>
      </template>
    </WsPopup>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_order_shop_product";
import parentModel from "@/__vue2stone_cms/models/shop_order";
export default {
  metaInfo() {
    return {
      title: "訂單列表",
    };
  },
  data() {
    return {
      modelName: model.modelName,
      parentModelName: parentModel.modelName,
      selected: [],
      modelData: null,
      remarkPopup: {
        title: "訂單A123456收件備註內容",
        content: "內容內容",
      },
      showFields: ["uuid", "name", "email"],
      postTransform: function (modelData) {
        return modelData.map((item) => {
          return {
            user: item.shop_order.user.id,
            shop_order: item.shop_order.id,
            shop_order_shop_product: item.id,
            shop_product: item.shop_product.id,
            count: item.order_number || 0,
            remark: item.remark,
          };
        });
      },
      fields: {
        return_reason: {
          type: "select",
          label: "退貨原因",
          placeholder: "選擇退貨原因",
          items: [
            {
              text: "貨物狀況不佳",
              value: 0,
            },
          ],
          rules: "required",
          col: 4,
        },
        remark: {
          type: "textarea",
          label: "退訂備註",
          placeholder: "輸入...",
          rules: "required",
          col: 12,
        },
      },
    };
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
    _modelDataParams() {
      return {
        shop_order: this.$route.params.id,
      };
    },
    _modelDataUrl() {
      return `/${this.modelName}`;
    },
    _tableFields() {
      return {
        product: {
          type: "info",
          label: "商品",
          fields: {
            name: {
              type: "text",
            },
            id: {
              type: "number",
            },
            spec: {
              type: "text",
            },
          },
        },
        per_price: {
          type: "info",
          label: "單價",
          fields: {
            custom_price: {
              type: "custom",
              getValue(data) {
                if (data.price) {
                  return `NT$ ${data.price}`;
                } else {
                  return `NT$ 0`;
                }
              },
            },
          },
        },
        return_count: {
          type: "state",
          label: "退訂數量",
          fields: {
            order_number: {
              type: "select",
              items: [
                {
                  text: "0",
                  value: 0,
                },
                {
                  text: "1",
                  value: 1,
                },
                {
                  text: "2",
                  value: 2,
                },
              ],
              customItems: function (modelData) {
                let _items = [];
                if (modelData.count) {
                  for (let i = 0; i <= modelData.count; i++) {
                    _items.push({
                      text: i,
                      value: i,
                    });
                  }
                }
                return _items;
              },
            },
          },
        },
        total_price: {
          type: "info",
          label: "單品小計",
          fields: {
            custom_total_price: {
              type: "custom",
              getValue(data) {
                if (data.price && data.order_number) {
                  return "NT$ " + data.price * data.order_number;
                } else {
                  return "NT$ 0";
                }
              },
            },
          },
        },
        return_remark: {
          type: "btn-popup",
          label: "退貨備註",
          fields: {
            return_remark: {
              type: "textarea",
              label: "退貨備註",
              placeholder: "輸入...",
            },
          },
        },
      };
    },
  },
  methods: {
    async $_onExportAll() {
      //下載檔案
      // const res = await S_API_User.getExportUrl();
      // window.open(res.data, "_blank");
    },
    $_onUpdateSelectd($event) {
      this.selected = $event;
    },
    $_goRouterReturnGoods($event) {
      this.$router.push(`/part_return/${$event.id}`);
    },
    $_remarkPopupOpen() {
      this.$refs.remarkPopup.open();
    },
    $_RemarkPopupClose() {
      this.$refs.remarkPopup.close();
    },
    $_onInput($event) {
      this.modelData = $event;
    },
  },
};
</script>